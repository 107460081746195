import { createStore } from 'vuex'

import correo from './index'

const store = createStore({
    modules: {
        correo
    }
})

export default store