export const correos = (state, datos) => {
    state.correo = datos
}

/*export const mutAtletas = (state, dato) => {
    state.atletas = dato[0]
    state.evento = dato[1]
    state.categorias = dato[2]
    state.estados = dato[3]
    state.clubs = dato[4]
}
/*
export const updateAtleta = (state, atleta) =>{
    const idx = state.atletas.map( e => e.id ).indexOf( atleta.id )
    state.atletas[idx] = atleta
}


export const selectAtleta = (state, atleta) => {
    state.atletaseleccionado = atleta
}

export const deleteAtleta = (state, id) => {
    state.atletas = state.atletas.filter(atleta => atleta.id !== id)
}

export const confirmarPagoId = (state, id) => {
    const idx = state.atletas.map(e => e.id).indexOf(id)
    state.atletas[idx].validar_pago = '1'
}

export const selectedEvento = (state, evento) => {
    state.evento = evento
}

export const vaciar = (state) => {
    state.atletas = [];
}

export const setCategorias = (state, data) => {
    console.log('state', data.categorias)
    state.categorias = data.categorias
    state.clubs = data.clubs
}*/