
import axios from 'axios'

const eventosApi = axios.create({
    baseURL: 'https://ctene5k10k.chdesarrolloweb.com/api'
    // baseURL: 'http://localhost:8000/'
    // baseURL: 'https://api.purocrono.com'
})

export default eventosApi


