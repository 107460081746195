import eventosApi from '@/api/eventosApi.js'


export const enviarCorreo = async ({ commit }, datos) => {
    const resp = await eventosApi.post(`/api-correos`, datos)
    commit('correos', datos)
    return resp.data
}


/*
export const setAtletas = async ({ commit }, evento) => {
    const atletas = await generalApi.get(`/v1/atleta/${evento}/categoria`)
    const a = atletas.data.atletas
    const e = atletas.data.evento
    const c = atletas.data.categorias
    const es = atletas.data.estados
    const cl = atletas.data.clubs
    commit('mutAtletas', [a, e, c, es, cl])
}

export const setAtletasInscripciones = async ({ commit }, evento) => {
    const atletas = await generalApi.get(`/v1/atleta/${evento}/inscripciones`)
    const a = atletas.data.atletas
    const e = atletas.data.evento
    const c = atletas.data.categorias
    const es = atletas.data.estados
    //const cl = atletas.data.clubs
    commit('mutAtletas', [a, e, c, es])
}

export const selectAtleta = async ({ commit }, id) => {
    commit('selectAtleta', id)
}

export const atletaseleccionado = ({ commit }, atleta) => {
    commit('selectAtleta', atleta)
}

export const selectedEvento = ({ commit }, evento) => {
    commit('selectedEvento', evento)
}

export const selectedEventoInscripciones = ({ commit }, evento) => {
    commit('selectedEvento', evento)
}

export const seledEvento = ({ commit }, evento) => {
    commit('selectedEvento', evento)
}

export const updateAtleta = async ({ commit }, atleta) => {
    console.log('Atletaaaaaa');
    console.log(atleta);
    const resp = await generalApi.put(`/v1/atleta/${atleta[1]}`, atleta[0])
}
export const createAtleta = async ({ commit }, atleta) => {
    const resp = await generalApi.post(`/v1/atleta/register`, atleta)
    return resp.data.id
}
export const deleteAtleta = async ({ commit }, id) => {
    await generalApi.delete(`/v1/atleta/${id}`)
    commit('deleteAtleta', id)
    return id
}

export const confirmarPagoId = async ({ commit }, id) => {
    const data = await generalApi.post(`/v1/atleta/${id}/confirmar-pago`)
    commit('confirmarPagoId', id)
    return data
}

export const AsignarDorsal = async ({ commit }, dato) => {
    const data = await generalApi.post(`/v1/atleta/${dato.id}/asignar-dorsal`, dato)
    //commit('AsignarDorsal', id, dorsal)
    return data
}
/*
export const confirmarPagoId = async ({ commit }, dorsal, id) => {
    await generalApi.post(`/v1/atleta/${id}/confirmar-pago`)
    commit('confirmarPagoId', id)
    return id
}


export const vaciar = async ({ commit }, id) => {
    await generalApi.delete(`/v1/atleta/vaciar/${id}`)
    commit('vaciar')
    return id
}


export const setCats = ({ commit }, data) => {
    commit('setCategorias', data)
}
*/
