// import { createRouter } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Index',
        component: () => import('../page/index.vue')
    },
    {
        path: '/contactanos',
        name: 'contactanos',
        component: () => import('../page/contactanos.vue')
    },
    {
        path: '/eventos',
        name: 'eventos',
        component: () => import('../page/eventos.vue'),
    },
    {
        path: '/blog',
        name: 'Blog',
        component: () => import('../page/blog.vue'),
    },
    {
        path: '/costos-la-asuncion-corre-de-noche',
        name: 'Costos',
        component: () => import('../page/Precio.vue'),
    },
    {
        path: '/costos-caribbean-9-6k',
        name: 'CostosCaribbean',
        component: () => import('../page/PrecioCaribbean.vue'),
    },
    // {
    //     path: '/costos-caribbean-kid-2-5k',
    //     name: 'CostosCaribbean',
    //     component: () => import('../page/PrecioCaribbean.vue'),
    // },
    {
        path: '/informacion-y-reglamentos-de-caribbean-9-6k',
        name: 'ReglamentosCaribbean',
        component: () => import('../page/reglamentos-caribbean.vue'),
    },
    // {
    //     path: '/informacion-y-reglamentos-de-caribbean-kid-2-5k',
    //     name: 'ReglamentosCaribbeanKid',
    //     component: () => import('../page/reglamentos-caribbean-kid.vue'),
    // },
    // {
    //     path: '/blog/:slug',
    //     name: 'blog-resultados',
    //     component: () => import('../modules/web/view/ResultadosSlug.vue'),
    //     props: (route) => {
    //       return {
    //         slug: route.params.slug
    //       }
    //     }
    //   },
    //   {
    //     path: '/eventos/:slug/:categoria',
    //     name: 'slug-resultados-categorias',
    //     component: () => import('../modules/web/view/ResultadosSlug.vue'),
    //     props: (route) => {
    //       return {
    //         slug: route.params.slug,
    //         categoria: route.params.categoria
    //       }
    //     }
    //   },
    //   {
    //     path: '/eventos/:slug/:sexo/evento-por-sexo',
    //     name: 'slug-resultados-sexo',
    //     component: () => import('../modules/web/view/ResultadosSlug.vue'),
    //     props: (route) => {
    //       return {
    //         slug: route.params.slug,
    //         sexo: route.params.sexo,
    //       }
    //     }
    //   },
    //   {
    //     path: '/eventos/:slug/:categoria/:sexo',
    //     name: 'slug-resultados-categorias-sexo',
    //     component: () => import('../modules/web/view/ResultadosSlug.vue'),
    //     props: (route) => {
    //       return {
    //         slug: route.params.slug,
    //         categoria: route.params.categoria,
    //         sexo: route.params.sexo,
    //       }
    //     }
    //   },
    // {
    //   path: '/about',
    //   name: 'About',
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // },

    //   {
    //     path: '/registro/:token?',
    //     name: 'Registro',
    //     component: () => import('../modules/register/view/Registro.vue'),
    //     props: (route) => {
    //       return {
    //         token: route.params.token,
    //       }
    //     }
    //   },
    //   {
    //     path: '/registro/comercio/:token',
    //     name: 'Comercio',
    //     component: () => import('../modules/register/view/RegistroComercio.vue'),
    //     props: (route) => {
    //       return {
    //         token: route.params.token,
    //       }
    //     }
    //   },
    //   {
    //     path: '/dorsal/:token?',
    //     name: 'dorsalToken',
    //     component: () => import('../modules/register/view/Dorsal.vue'),
    //     props: (route) => {
    //       return {
    //         token: route.params.token,
    //       }
    //     }
    //   },
    //   {
    //     path: '/consultar',
    //     name: 'Consultar',
    //     component: () => import('../modules/register/view/Consulta.vue'),
    //   },
    //   {
    //     path: '/validar/:token',
    //     name: 'Validar',
    //     component: () => import('../modules/register/view/Registro.vue'),
    //     props: (route) => {
    //       return {
    //         token: route.params.token,
    //       }
    //     }
    //   },/**/



    /* {
         path: '/politicas-de-privacidad',
         name: 'politicas',
         component: () => import('../modules/web/view/Politicas.vue')
     },
     {
         path: '/terminos-y-condiciones',
         name: 'terminos',
         component: () => import('../modules/web/view/TerminosCondiciones.vue')
     },*/

    { path: "/:pathMatch(.*)*", component: () => import('@/page/404page'), name: 'page404' }

]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        }
    }
})

export default router
